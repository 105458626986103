export const assemblyFields = []
export const assemblyElementsForm = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required'
  },
  {
    name: 'elements',
    elementType: 'select',
    rules: 'required',
    multiple: true,
    label: 'Elements'
  }
]

export const assemblyForm = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required'
  },
  {
    name: 'type',
    rules: 'required',
    elementType: 'select',
    label: 'Type'
  },
  {
    name: 'ccAssemblyId',
    label: 'CC Assembly Id',
    rules: 'required'
  },
  {
    name: 'description',
    elementType: 'textarea',
    label: 'Description'
  },
  {
    name: 'category',
    elementType: 'select',
    label: 'Assembly Category',
    rules: 'required'
  },
  {
    name: 'suggestionsCategory',
    elementType: 'select',
    label: 'Assembly Subcategory',
    rules: 'required'
  },
  {
    name: 'elements',
    elementType: 'autocomplete',
    multiple: true,
    label: 'Elements Without Subcategory'
  },
  {
    name: 'categories',
    elementType: 'component',
    label: 'Categories'
  },
  ...assemblyFields,
  {
    name: 'notes',
    label: 'Notes'
  },
  {
    name: 'citations',
    label: 'Citations'
  }
]

export const elementTypeHasField = (element) => {
  return element.cpdVersion === 3 && element.type === 'material'
}
