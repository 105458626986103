// AssemblyCategoryForm.vue
<template>
  <v-card class="assembly-category-form">
    <v-card-title>
      <!-- Slot for the card title -->
      <slot name="title">
        Categories
      </slot>
    </v-card-title>

    <v-card-text>
      <!-- List of existing categories -->
      <div v-if="localCategories.length > 0">
        <v-list>
          <v-list-item
            v-for="(category, index) in localCategories"
            :key="index"
          >
            <v-list-item-content>
              <!-- Slot for custom category item rendering with scoped slot -->
              <slot
                name="category-item"
                :category="category"
                :index="index"
              >
                <v-list-item-title>{{ category.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  Elements:
                  <v-chip
                    v-for="(element, i) in category.elements"
                    :key="i"
                    x-small
                    class="mr-1"
                  >
                    {{ getElementLabel(element) }}
                  </v-chip>
                </v-list-item-subtitle>
              </slot>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
                small
                color="primary"
                @click="editCategory(index)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item-action>
              <v-btn
                icon
                small
                color="error"
                @click="confirmRemove(index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>

      <slot
        v-if="localCategories.length === 0"
        name="empty-state"
      >
        <v-alert
          type="info"
          text
        >
          No categories added yet
        </v-alert>
      </slot>

      <!-- Form to add/edit categories -->
      <v-form
        ref="form"
        v-model="isFormValid"
        class="mt-4"
      >
        <v-card outlined>
          <v-card-title>
            {{ isEditing ? 'Edit Category' : 'Add New Category' }}
          </v-card-title>

          <v-card-text>
            <!-- Slot for custom form fields above the standard ones -->
            <slot name="before-form-fields" />

            <v-text-field
              v-model="currentCategory.name"
              label="Category Name"
              :rules="nameRules"
              required
            />

            <v-autocomplete
              v-model="currentCategory.elements"
              :items="allElements"
              item-text="name"
              :item-value="getItemValue"
              label="Select Elements"
              :rules="elementsRules"
              chips
              multiple
              return-object
              small-chips
              deletable-chips
              required
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="isEditing"
              text
              color="grey darken-1"
              @click="cancelEdit"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!isFormValid"
              @click="saveCategory"
            >
              {{ isEditing ? 'Update' : 'Add' }} Category
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-card-text>

    <!-- Confirmation dialog for deletion -->
    <v-dialog
      v-model="showDeleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Confirm Deletion
        </v-card-title>

        <v-card-text>
          Are you sure you want to remove this category?
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="showDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            text
            @click="removeCategory"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: 'AssemblyCategoryForm',
  model: {
    prop: 'categories',
    event: 'change'
  },
  props: {
    // Categories are now passed via v-model
    categories: {
      type: Array,
      default: () => []
    },
    // Options for the dropdown, passed from parent
    allElements: {
      type: Array,
      required: true,
      // Each option should have name and _id
      validator: options => options.every(option =>
        option.hasOwnProperty('name') && option.hasOwnProperty('_id')
      )
    }
  },
  data () {
    return {
      localCategories: [], // Local copy of categories for v-model
      currentCategory: {
        name: '',
        elements: []
      },
      editingIndex: -1,
      isEditing: false,
      isFormValid: false,
      nameRules: [
        v => !!v || 'Category name is required'
      ],
      elementsRules: [
        v => (v && v.length > 0) || 'At least one element must be selected'
      ],
      showDeleteDialog: false,
      deleteIndex: -1
    }
  },
  watch: {
    // Watch for external changes to the categories prop
    categories: {
      immediate: true,
      handler (newVal) {
        // Only update local copy if it's different to avoid infinite loops
        if (newVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.localCategories)) {
            this.localCategories = JSON.parse(JSON.stringify(newVal))
          }
        }
      }
    }
  },
  methods: {
    saveCategory () {
      if (!this.isFormValid) return

      if (this.isEditing) {
        // Update existing category
        this.localCategories[this.editingIndex] = { ...this.currentCategory }
        this.isEditing = false
        this.editingIndex = -1
      } else {
        // Add new category
        this.localCategories.push({ ...this.currentCategory })
      }

      // Reset form
      this.resetForm()

      // Emit updated categories for v-model
      this.$emit('change', [...this.localCategories])
    },

    editCategory (index) {
      // Create a deep copy of the category to avoid reference issues
      this.currentCategory = JSON.parse(JSON.stringify(this.localCategories[index]))
      this.editingIndex = index
      this.isEditing = true
    },

    confirmRemove (index) {
      this.deleteIndex = index
      this.showDeleteDialog = true
    },
    getItemValue (item) {
      return item._id || item
    },
    removeCategory () {
      this.localCategories.splice(this.deleteIndex, 1)

      // Cancel editing if we're removing the category being edited
      if (this.editingIndex === this.deleteIndex) {
        this.cancelEdit()
      } else if (this.editingIndex > this.deleteIndex) {
        // Adjust editing index if we removed a category before it
        this.editingIndex--
      }

      // Close the dialog
      this.showDeleteDialog = false
      this.deleteIndex = -1

      // Emit updated categories for v-model
      this.$emit('input', [...this.localCategories])
    },

    cancelEdit () {
      this.resetForm()
      this.isEditing = false
      this.editingIndex = -1
    },

    resetForm () {
      this.currentCategory = {
        name: '',
        elements: []
      }
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },

    getElementLabel (element) {
      // If element is an object with name property, return that
      if (element && typeof element === 'object' && element.name) {
        return element.name
      }

      // If element is a value, look up the name
      const option = this.allElements.find(opt => opt._id === element)
      return option ? option.name : element
    }
  }
}
</script>
