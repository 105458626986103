<template>
  <div v-if="loading">
    <div class="text-center pa-7">
      <v-progress-circular indeterminate />
    </div>
  </div>
  <div v-else>
    <SimpleTableForm
      :id="id"
      title="Create Assembly"
      :table-config="tableConfig"
      :table-config-categories="tableConfigCategories"
      :return-route="adminRoute"
      :server-type="serverType"
      :loading="loading"
      @created="$emit('created')"
      @form-loaded="formLoaded"
      @form-change="formChanged"
    >
      <template v-slot:other-fields="{tableData, errors}">
        <ValidationProvider
          v-slot="{ errors }"
        >
          <AssemblyCategoryForm
            v-model="tableData.categories"
            :all-elements="tableConfigCategories['elements']"
          />
        </ValidationProvider>
      </template>
    </SimpleTableForm>
  </div>
</template>

<script>

import SimpleTableForm from '@/components/form/SimpleTableForm'
import { assemblyForm, assemblyElementsForm } from './constants'
import AssemblyCategoryForm from '@/views/admin/assemblies/AssemblyCategoryForm.vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'AssemblyForm',
  namespaced: true,
  components: {
    AssemblyCategoryForm,
    SimpleTableForm,
    ValidationProvider
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      allCategories: [],
      allElements: [],
      allValidElements: [],
      loading: true,
      tableConfig: assemblyForm,
      elementsTableConfig: assemblyElementsForm,
      elementTableConfigCategories: {},
      tableConfigCategories: {},
      serverType: 'assemblies',
      adminRoute: '/admin/assemblies'
    }
  },
  async created () {
    this.tableConfigCategories['type'] = [{ name: 'material' }]
    this.allElements = (await this.$axios.get(`/elements`)).data
    this.allCategories = (await this.$axios.get('/element_categories')).data

    this.tableConfigCategories['category'] = this.categoryByType('material')
    this.tableConfigCategories['elements'] = this.elementsByType('material')

    this.loading = false
  },
  methods: {
    categoryByType (type) {
      return this.allCategories
        .filter((category) => category.type === type && category.cpdVersion === 3)
        .map((category) => ({
          _id: category.name,
          name: category.name,
          subcategories: category.subCategories
        }))
    },
    elementsByType (type) {
      return this.allElements.filter((element) =>
        element.type === type && element.cpdVersion === 3)
    },
    formLoaded ({ element }) {
      if (element.category) {
        this.tableConfigCategories['suggestionsCategory'] = this.categoryByType(element.type)
          .filter(({ name }) => name === element.category)
          .flatMap(({ subcategories }) => subcategories)
      }
    },
    formChanged (value) {
      this.tableConfigCategories['category'] = this.categoryByType(value.type || 'material')
      this.tableConfigCategories['elements'] = this.elementsByType(value.type || 'material')
      this.tableConfigCategories['suggestionsCategory'] = this.categoryByType(value.type || 'material')
        .filter(({ name, subCategories }) => name === value.category)
        .flatMap(({ subcategories }) => subcategories)
    }
  }
}
</script>
